import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { checkbox as styles } from "./styles";

import {
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox as MuiCheckbox,
	Fab,
	withStyles
} from "@material-ui/core";

import {
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
	CheckCircle as CheckCircleIcon
} from "@material-ui/icons";

class Checkbox extends React.Component {

	check = (key) => {
		const { name, filters } = this.props;
		const itemName = name.toLowerCase();

		if (filters[itemName].indexOf(key) > -1) {
			return true;
		}
		return false;
	};

	getCheckboxValue = (key) => key;

	renderCheckbox () {
		const { classes, values, onChange, name, filters } = this.props;
		const itemName = name.toLowerCase();

		return (
			<div className={classes.root}>
				<FormControl
					className={classes.formControl}
					component="fieldset"
				>
					<FormGroup>
						{values.map((action) => (
							<FormControlLabel
								classes={{
									label: classes.colorWhite
								}}
								control={
									<MuiCheckbox
										className={classes.icon}
										checked={filters[itemName].indexOf(action.key) > -1}
										checkedIcon={
											<CheckCircleIcon className={classes.colorWhite} />
										}
										icon={
											<RadioButtonUncheckedIcon
												className={classes.colorWhite}
											/>
										}
										onChange={onChange}
										value={action.key}
									/>
								}
								key={`${name}-${action.key}`}
								label={action.label}
							/>
						))}
					</FormGroup>
				</FormControl>
			</div>
		);
	}

	render () {
		const { classes, values, onClick, colored, name } = this.props;

		if ([
			"type",
			"floor"
		].indexOf(name) < 0) {
			return this.renderCheckbox();
		}
		return values.map((action) => {
			const imgBackground = `backgroundColor${action.key.replace(/\D/ugmi, "")}`;

			if (action.key === "all") {
				return null;
			}
			if (name === "floor") {
				action.label = (
					<span className="superE">
						{action.key}
					</span>
				);
				if (Number(action.key) === 0 || action.key === "RDC") {
					action.label = "RDC";
				}
				if (action.key === "all") {
					action.label = "tous";
				}
				if (Number(action.key) === 1) {
					action.label = (
						<span className="superER">
							{action.key}
						</span>
					);
				}
			}
			const checked = this.check(action.key);
			return (
				<Fab
					className={classNames(
						classes.action,
						colored ? classes.colored : "",
						checked	? "active" : "",
						colored && checked ? imgBackground : ""
					)}
					key={action.key}
					onClick={(event) => onClick(event, action.key)}
					value={action.key}
				>
					{action.label}
				</Fab>
			);
		});
	}

}

Checkbox.propTypes = {
	classes: PropTypes.object.isRequired,
	colored: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	values: PropTypes.array.isRequired
};

Checkbox.defaultProps = {
	colored: false
};

export default withStyles(styles)(Checkbox);
