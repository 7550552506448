import { SET_WINDOW_WIDTH, SET_SEARCH_HIDDEN, SET_SIDEBAR_HIDDEN, SET_SEARCH_OPENED, SET_SIDEBAR_OPENED, SET_MOBILE } from "./constants";

export const windowSizeChange = ({ state }) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_WINDOW_WIDTH,
		width: window.innerWidth
	});
	resolve(state);
});

export const setSearchHidden = (hidden) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_SEARCH_HIDDEN,
		hidden
	});
	resolve(hidden);
});

export const setSidebarHidden = (hidden) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_SIDEBAR_HIDDEN,
		hidden
	});
	resolve(hidden);
});

export const openSearch = () => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_SEARCH_OPENED
	});
	resolve();
});

export const openSidebar = () => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_SIDEBAR_OPENED
	});
	resolve();
});

export const setMobile = (isMobile) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_MOBILE,
		isMobile
	});
	resolve();
});
