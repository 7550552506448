export const checkbox = (theme) => ({
	root: {
		display: "block"
	},
	formControl: {
		padding: 0
	},
	group: {
		margin: `${theme.spacing.unit}px 0`
	},
	action: {
		"&:last-child": {
			margin: 0
		},
		"&:hover": {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.common.white
		},
		"&:active, &.active": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white
		},
		width: 35,
		height: 35,
		margin: `0 ${theme.spacing.unit}px 0 0`,
		borderRadius: "100%",
		fontSize: "0.75rem",
		minHeight: 1,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[3],
		"& span": {
			margin: "auto"
		},
		"&:hover span, &:active span, &.active span": {
			color: theme.palette.common.white
		}
	},
	colored: {
		backgroundColor: theme.palette.background.paper
		// "&$action:nth-child(1):hover,&$action:nth-child(1):active,&$action:nth-child(1).active": {
		// 	backgroundColor: theme.app.backgroundColor1
		// },
		// "&$action:nth-child(2):hover,&$action:nth-child(2):active,&$action:nth-child(2).active": {
		// 	backgroundColor: theme.app.backgroundColor2
		// },
		// "&$action:nth-child(3):hover,&$action:nth-child(3):active,&$action:nth-child(3).active": {
		// 	backgroundColor: theme.app.backgroundColor3
		// },
		// "&$action:nth-child(4):hover,&$action:nth-child(4):active,&$action:nth-child(4).active": {
		// 	backgroundColor: theme.app.backgroundColor4
		// },
		// "&$action:nth-child(5):hover,&$action:nth-child(5):active,&$action:nth-child(5).active": {
		// 	backgroundColor: theme.app.backgroundColor5
		// },
		// "&$action:nth-child(n + 6):hover,&$action:nth-child(n + 6):active,&$action:nth-child(n + 6).active": {
		// 	backgroundColor: theme.palette.primary.main
		// }
	},
	colorWhite: {
		color: `${theme.palette.common.white} !important`,
		fontSize: "1rem",
		textTransform: "capitalize"
	},
	icon: {
		padding: theme.spacing.unit
	}
});
export const radio = (theme) => ({
	action: {
		"&:last-child": {
			margin: 0
		},
		"&:hover": {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.common.white
		},
		"&:active, &.active": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white
		},
		"&.all": {
			textTransform: "capitalize"
		},
		width: 35,
		height: 35,
		margin: `0 ${theme.spacing.unit}px 0 0`,
		borderRadius: "100%",
		fontSize: "0.75rem",
		minHeight: 1,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[3],
		"& span": {
			margin: "auto"
		},
		"&:hover span, &:active span, &.active span": {
			color: theme.palette.common.white
		}
	},
	colored: {
		backgroundColor: theme.palette.background.paper,
		"&$action:nth-child(1):hover,&$action:nth-child(1):active,&$action:nth-child(1).active": {
			backgroundColor: theme.app.backgroundColor1
		},
		"&$action:nth-child(2):hover,&$action:nth-child(2):active,&$action:nth-child(2).active": {
			backgroundColor: theme.app.backgroundColor2
		},
		"&$action:nth-child(3):hover,&$action:nth-child(3):active,&$action:nth-child(3).active": {
			backgroundColor: theme.app.backgroundColor3
		},
		"&$action:nth-child(4):hover,&$action:nth-child(4):active,&$action:nth-child(4).active": {
			backgroundColor: theme.app.backgroundColor4
		},
		"&$action:nth-child(5):hover,&$action:nth-child(5):active,&$action:nth-child(5).active": {
			backgroundColor: theme.app.backgroundColor5
		},
		"&$action:nth-child(n + 6):hover,&$action:nth-child(n + 6):active,&$action:nth-child(n + 6).active": {
			backgroundColor: theme.palette.primary.main
		}
	},
	colorWhite: {
		color: `${theme.palette.common.white} !important`,
		fontSize: "1rem",
		textTransform: "capitalize"
	},
	icon: {
		padding: theme.spacing.unit
	}
});
export const range = (theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		"&:before": {
			content: "( !important)"
		}
	},
	wrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		"&:before": {
			content: "( !important)"
		}
	},
	textField: {
		backgroundColor: theme.palette.common.white,
		fontSize: "0.75rem",
		color: theme.palette.common.black,
		padding: `0px ${theme.spacing.unit / 2}px`,
		width: "100%",
		borderRadius: theme.spacing.unit * 10,
		marginRight: theme.spacing.unit * 2,
		marginTop: theme.spacing.unit,
		"&:last-child": {
			marginRight: 0
		}
	},
	adorment: {
		marginRight: theme.spacing.unit / 4,
		marginLeft: theme.spacing.unit / 4,
		fontSize: "0.7rem",
		"&:before": {
			content: ""
		}
	}
});
export const filter = (theme) => ({
	wrapper: {
		display: "flex",
		alignItems: "center",
		"&.mobile $hiddenMobile": {
			display: "inline-flex"
		}
	},
	icon: {
		display: "inline-flex",
		alignItems: "center",
		marginRight: theme.spacing.unit,
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main,
		fontSize: 30,
		width: 22,
		height: 22
	},
	name: {
		display: "inline-flex",
		alignItems: "center",
		marginRight: theme.spacing.unit,
		textTransform: "uppercase"
	},
	hiddenMobile: {
		display: "none"
	},
	actions: {
		display: "block",
		alignItems: "center",
		borderRadius: theme.spacing.unit * 12,
		padding: theme.spacing.unit / 2,
		width: "auto",
		backgroundColor: "transparent"
	},
	withBackground: {
		backgroundColor: theme.palette.background.paper
	},
	[theme.breakpoints.down("sm")]: {
		drawer: {
			width: "100vw"
		},
		drawerPaper: {
			width: "100vw"
		},
		actions: {
			justifyContent: "left",
			borderRadius: 20,
			textAlign: "left",
			width: "100%"
		}
	}
});

export default {
	checkbox,
	radio,
	range,
	filter
};
