export default (theme) => ({
	drawer: {
		width: "20vw",
		flexShrink: 0,
		minWidth: 320
		// boxShadow: "none"
	},
	drawerPaper: {
		width: "20vw",
		height: "100%",
		boxShadow: "none",
		padding: theme.spacing.unit * 2,
		backgroundColor: theme.palette.common.black,
		minWidth: 320
	},
	wrapper: {
		maxWidth: "60vw",
		margin: "0 auto"
	},
	hidden: {
		display: "none"
	},
	title: {
		color: theme.palette.common.white,
		margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`
	},
	icon: {
		width: "1rem",
		height: "1rem",
		fill: theme.palette.primary.main,
		marginRight: theme.spacing.unit
	},
	content: {
		"&$expanded": {
			margin: 0
		},
		fontWeight: 600,
		margin: "auto"
	},
	expanded: {
		minHeight: "auto"
	},
	panelRoot: {
		marginBottom: 20,
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		boxShadow: "none",
		"&::before": {
			height: 0
		}
	},
	panelSummaryRoot: {
		minHeight: 20,
		marginTop: 32,
		"&$expanded": {
			minHeight: "auto",
			marginTop: 32
		}
	},
	panelSummaryHeading: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		fontWeight: 600
	},
	panelSummaryIcon: {
		color: theme.palette.primary.main
	},
	panelDetails: {
		// backgroundColor: theme.palette.common.black,
		display: "block",
		padding: `0 ${theme.spacing.unit * 3}px`
	},
	resultHeaderAppBar: {
		marginBottom: theme.spacing.unit * 3,
		boxShadow: "none"
	},
	resultHeaderToolbar: {
		padding: 0,
		alignItems: "center"
	},
	resultHeaderActions: {
		width: "100%",
		textAlign: "right"
	},
	resultHeaderCount: {
		color: theme.palette.primary.main,
		fontWeight: "600",
		marginRight: theme.spacing.unit
	},
	resultHeaderTitle: {
		fontWeight: "600"
	},
	resultHeaderButton: {
		backgroundColor: theme.palette.background.default,
		marginRight: theme.spacing.unit,
		"&:last-child()": {
			marginRight: 0
		}
	},
	resultHeaderIcon: {
		color: theme.palette.primary.main
	},
	formControl: {
		padding: `0 ${theme.spacing.unit * 5}px`
	},
	colorWhite: {
		color: `${theme.palette.common.white} !important`,
		fontSize: "1.3rem"
	},
	actions: {
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		flexDirection: "column"
	},
	filterAction: {
		fontWeight: 600
	},
	btnApply: {
		padding: `0 ${theme.spacing.unit * 4}px`,
		fontSize: "1.1rem",
		borderRadius: theme.spacing.unit * 8,
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		marginTop: theme.spacing.unit * 3,
		overflow: "hidden"
	},
	btnReset: {
		color: "#fff",
		borderRadius: 100,
		marginTop: theme.spacing.unit * 2
	},
	toolbar: theme.mixins.toolbar,
	[theme.breakpoints.down("md")]: {
		btnApply: {
			fontSize: "1rem",
			padding: `0 ${theme.spacing.unit}px`
		}
	},
	[theme.breakpoints.down("sm")]: {
		drawer: {
			width: "100vw"
		},
		drawerPaper: {
			width: "100vw"
		}
	}
});
