import { theme } from "../../utils/ThemeProvider";
import styles from "./styles";
import classNames from "classnames";
import {
	withStyles,
	Grid,
	Typography,
	Button
} from "@material-ui/core";

import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/icons/arrowRight.svg";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { filters } from "../../store";
import { createjs } from "../../utils";

class Canvas extends Component {

	state = {
		filteredIds: [],
		pois: {},
		floors: {},
		loaded: 0,
		frame: 0,
		startX: null,
		stage: null,
		canvas: null,
		popup: {
			x: 0,
			y: 0,
			open: false,
			poi: null,
			color: "inherit"
		},
		direction: 1,
		mouseTrigger: false,
		rollInterval: false
	}

	componentDidMount () {
		this.setInitialState();
	}

	componentDidUpdate () {
		const { filteredIds } = this.state;
		const { ids } = this.props;
		if (JSON.stringify(filteredIds) !== JSON.stringify(ids)) {
			this.updateState(this.props);
		}
	}

	componentWillUnmount () {
		const { shapes } = this.props;
		const { stage } = this.state;
		// this.props.setFloor("all");
		stage.removeAllEventListeners();
		Object.keys(shapes).map((name) => {
			shapes[name].removeAllEventListeners();
			return shapes[name];
		});
		createjs.Ticker.removeAllEventListeners();
	}

	holdInterval = null;

	updateState = ({ ids, floor, pois, shapes }) => {
		this.setState((prevState) => ({
			...prevState,
			floor,
			filteredIds: ids,
			pois: pois.filter((poi) => {
				const [name] = Object.keys(poi);
				shapes[name].children[0].x = -100;
				shapes[name].children[0].y = -100;
				shapes[name].children[1].x = -100;
				shapes[name].children[1].y = -100;
				return ids.indexOf(name) > -1;
			})
		}), () => {
			this.update360(0);
		});
	}

	setInitialState = () => {
		const { floors, pois } = this.props;
		const canvas = document.getElementById("canvas");

		this.setState((prevState) => ({
			...prevState,
			canvas,
			stage: new createjs.Stage(canvas),
			floors,
			pois
		}), () => this.init());
	}

	setEvents = () => {
		const { shapes, data, colors, floors } = this.props;
		const textColor = theme.palette.primary.contrastText;
		Object.keys(shapes).map((name) => {
			const color = theme.app[`backgroundColor${data[name].type.replace(/\D/u, "")}`];
			const shape = shapes[name];
			shape.addEventListener("click", () => {
				this.props.history.push(`/bien/${shape.children[0].title}`);
			});
			shape.addEventListener("mouseover", () => {
				shape.children[1].color = color;
				shape.children[0].graphics._fill.style = textColor;
				document.body.style.cursor = "pointer";
			});
			shape.addEventListener("mouseout", () => {
				shape.children[1].color = textColor;
				shape.children[0].graphics._fill.style = color;
				document.body.style.cursor = "auto";
			});
			return shape;
		});
		// This is need for canvas with floors
		//
		// Object.keys(floors).map((floor) => {
		// 	floors[floor].map((floorPois) => {
		// 		floorPois.map((poi) => {
		// 			poi.addEventListener("click", () => {
		// 				this.props.history.push(`/bien/${poi.title}`);
		// 			});
		// 			poi.addEventListener("mouseover", () => {
		// 				this.setState((prevState) => ({
		// 					...prevState,
		// 					popup: {
		// 						open: true,
		// 						color: colors[poi.title],
		// 						poi: data[poi.title]
		// 					}
		// 				}));
		// 				poi.alpha = 0.75;
		// 				document.body.style.cursor = "pointer";
		// 			});
		// 			poi.addEventListener("mouseout", () => {
		// 				this.setState((prevState) => ({
		// 					...prevState,
		// 					popup: {
		// 						open: false
		// 					}
		// 				}));
		// 				poi.alpha = 0.45;
		// 				document.body.style.cursor = "auto";
		// 			});
		// 			return poi;
		// 		});
		// 		return floorPois;
		// 	});
		// 	return floor;
		// });
	}


	holdAnimation = (dir) => {
		this.update360(dir);
		if (!this.holdInterval) {
			this.holdInterval = setInterval(() => {
				this.update360(dir);
			}, 50);
		}
	}

		endAnimation = () => {
			if (this.holdInterval) {
				clearInterval(this.holdInterval);
				this.holdInterval = null;
			}
		}

	update360 = (dir) => {
		const { canvas, stage } = this.state;
		const { floor, shapes, floors, framesCount } = this.props;

		this.setState((prevState) => {
			let frame = prevState.frame + (Number(dir));
			if (frame < 0) {
				frame = framesCount - 1;
			} else if (frame > framesCount - 1) {
				frame = 0;
			}
			return ({
				...prevState,
				frame
			});
		}, () => {
			this.resetStage();

			// This is need for canvas with floors
			// if (floor === "all") {
			this.state.pois.map((poi) => {
				const [name] = Object.keys(poi);
				const values = poi[name].coordinates;
				const result = values.filter((value) => value._column === this.state.frame);

				shapes[name].children[0].x = -100;
				shapes[name].children[0].y = -100;
				shapes[name].children[1].x = -100;
				shapes[name].children[1].y = -100;

				if (result.length > 0) {
					const x = canvas.width * result[0]._x;
					const y = canvas.height * result[0]._y;
					shapes[name].children[0].x = x;
					shapes[name].children[0].y = y;
					shapes[name].children[1].x = x;
					shapes[name].children[1].y = y;
				}
				stage.addChild(shapes[name]);
				return poi;
			});
			// } else {
			// 	floors[floor].map((shape) => {
			// 		const poi = shape[this.state.frame];
			// 		if (this.state.filteredIds.indexOf(poi.title) > -1) {
			// 			stage.addChild(poi);
			// 		}
			// 		return shape;
			// 	});
			// }
			stage.update();
		});
	}

	// -------------------------------
	addNavigation = () => {
		const { stage } = this.state;
		let dragTrigger = false;

		stage.on("stagemousedown", (event) => {
			dragTrigger = true;
			this.mousePressed(event);
		});
		stage.on("stagemouseup", () => {
			dragTrigger = false;
			document.body.style.cursor = "auto";
		});
		stage.on("stagemousemove", (event) => {
			if (dragTrigger === true) {
				this.mouseMoved(event);
			}
			this.setState((prevState) => {
				prevState.popup.x = event.nativeEvent.clientX - 100;
				prevState.popup.y = event.nativeEvent.clientY + 20;
				return ({
					...prevState
				});
			});
		});

		document.body.style.cursor = "auto";
	}

	mousePressed = (event) => {
		this.setState((prevState) => ({
			...prevState,
			startX: event.rawX
		}));
		document.body.style.cursor = "w-resize";
	}

	mouseMoved = (event) => {
		const { startX } = this.state;
		const dx = event.rawX - startX;
		const absDx = Math.abs(dx);

		if (absDx > 5) {
			this.update360(-dx / absDx);
			this.setState({ startX: event.rawX });
		}
	}

	handleTick = () => {
		this.state.stage.update();
	}

	init = () => {
		const { canvas, stage } = this.state;

		if (!canvas || !canvas.getContext) {
			return;
		}
		stage.enableMouseOver(24);
		createjs.Touch.enable(stage);

		this.resetStage();
		this.addNavigation();
		this.setEvents();
		// TICKER
		createjs.Ticker.addEventListener("tick", this.handleTick);
		createjs.Ticker.framerate = 24;
		createjs.Ticker.useRAF = true;
	}

	resetStage = () => {
		const { bg, bmp, floorsImages, floor } = this.props;
		const { stage } = this.state;
		stage.removeAllChildren();
		stage.addChild(bg);
		stage.addChild(bmp);
		// This is need for canvas with floors
		// bmp.image = floorsImages[floor][this.state.frame];
		bmp.image = floorsImages.all[this.state.frame];
	}

	renderPopup () {
		const { popup } = this.state;
		const { open, x, y, color, poi } = popup;
		const pieces = poi ? Number(poi.type.replace(/\D/ugmi, "")) : 0;

		return (
			<Grid
				container
				direction="row"
				alignItems="center"
				alignContent="center"
				style={{
					display: open ? "flex" : "none",
					position: "absolute",
					top: y,
					left: x,
					width: 200,
					height: 60,
					backgroundColor: "#fff",
					zIndex: 99999,
					border: `1px solid ${color}`
				}
				}
			>
				<Grid
					item
					xs={4}
					style={{
						backgroundColor: color,
						color: "white",
						height: "100%"
					}}
				>
					<Typography
						variant="h1"
						color="inherit"
						style={{
							display: "flex",
							alignItems: "center",
							alignContent: "center",
							textAlign: "center",
							height: "100%",
							justifyContent: "center"
						}}
					>
						{poi ? poi.lot : null}
					</Typography>
				</Grid>
				<Grid
					item
					xs={8}
					style={{
						padding: "0 16px"
					}}
				>
					<Typography
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "left",
							color
						}}
						variant="h4"
					>
						{poi ? `${pieces} ${(pieces === 1) ? "pièce" : "pièces"}` : null}
					</Typography>
					<Typography
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "left",
							color,
							fontWeight: 700
						}}
						variant="h1"
					>
						{poi ? (
							<span className="squared">
								{`${poi.surface} m`}
							</span>
						) : null}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	render () {
		const { classes } = this.props;
		return (
			<>
				<div className={classes.root}>
					<canvas
						className={classes.canvas}
						id="canvas"
						width="1920"
						height="1080"
					/>
					{this.renderPopup()}
					<div className={classes.arrowsContainer}>
						<Button
							className={classNames(classes.arrows, classes.arrowLeft)}
							variant="contained"
							color="primary"
							onMouseDown={() => {
								this.holdAnimation(-1);
							}}
							onMouseUp={this.endAnimation}
							onTouchStart={() => {
								this.holdAnimation(-1);
							}}
							onTouchEnd={this.endAnimation}
						>
							<ArrowLeftIcon
								className={classNames(classes.arrowIcon)}
							/>
						</Button>
						<Button
							className={classNames(classes.arrows, classes.arrowRight)}
							variant="contained"
							color="primary"
							onMouseDown={() => {
								this.holdAnimation(1);
							}}
							onMouseUp={this.endAnimation}
							onTouchStart={() => {
								this.holdAnimation(1);
							}}
							onTouchEnd={this.endAnimation}
						>
							<ArrowRightIcon
								className={classNames(classes.arrowIcon)}
							/>
						</Button>
					</div>
				</div>
			</>
		);
	}

}

const mapStateToProps = (state) => ({
	...state.filtered,
	...state.canvas,
	pois: state.canvas.all.pois,
	data: state.pois,
	floor: state.filters.floor
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	setFloor: filters.setFloor
}, dispatch);

Canvas.propTypes = {
	bg: PropTypes.object.isRequired,
	bmp: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	colors: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	floor: PropTypes.string.isRequired,
	floors: PropTypes.object.isRequired,
	floorsImages: PropTypes.object.isRequired,
	framesCount: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	ids: PropTypes.array.isRequired,
	pois: PropTypes.array.isRequired,
	setFloor: PropTypes.func.isRequired,
	shapes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Canvas)));
