import {
	Fab,

	withStyles
} from "@material-ui/core";
import {
	ArrowBack as ArrowBackIcon
} from "@material-ui/icons";

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import styles from "./styles";

import {
	Search,
	Sidebar,
	Header,
	Canvas,
	Logo
} from "../../components";

import { Wrapper } from "../index";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openSearch } from "../../store/helpers";

class Layout extends Component {

	render () {
		const { classes } = this.props;
		return (
			<Wrapper
				id="layout"
				className={classes.wrapper}
			>
				<Header />
				<main
					className={classes.main}
				>
					<div className={classes.toolbar} />
					<Fab
						className={classNames(classes.btnBack)}
						component={Link}
						to="/"
						variant="extended"
					>
						<ArrowBackIcon className={classNames(classes.primaryColor, classes.arrowBackIcon)} />
						{"Retour"}
					</Fab>
					<Canvas />
					<Logo />
				</main>
				<Sidebar />
				<Search />
			</Wrapper>
		);
	}

}

const mapStateToProps = (state) => ({
	...state.helpers
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	handleOpenSearch: openSearch
}, dispatch);

Layout.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Layout));
