export const card = (theme) => ({
	"@global": {
		".benefitWrapper": {
			alignItems: "normal !important"
		}
	},
	card: {
		backgroundColor: theme.palette.common.white,
		marginBottom: theme.spacing.unit,
		"&:hover": {
			boxShadow: theme.shadows[3],
			cursor: "pointer"
		},
		"&:last-child": {
			marginBottom: 0
		}
	},
	cardHeader: {
		alignItems: "start"
	},
	cardAction: {
		boxShadow: "none",
		width: 24,
		height: 24,
		minHeight: "auto"
		// padding: 0
	},
	btn: {
		fontSize: 16,
		fill: "#888888",
		width: 16,
		height: 16
		// backgroundColor: theme.palette.background.paper
	},
	avatar: {
		fontSize: "0.55rem",
		fontWeight: 600,
		width: 35,
		height: 35
	},
	title: {
		fontWeight: 600,
		color: "#000",
		marginBottom: theme.spacing.unit
	}
});

export const item = (theme) => ({
	wrapper: {
		display: "flex",
		alignItems: "center",
		margin: `${theme.spacing.unit}px 0`
	},
	icon: {
		display: "inline-flex",
		width: 24,
		height: 24,
		marginRight: theme.spacing.unit,
		fill: "#cccccc",
		flexShrink: 0
	},
	text: {
		display: "inline-flex",
		color: "#888888",
		fontSize: "0.9rem",
		fontWeight: 600
		// textTransform: "capitalize"
	}
});
