import db from "../../data/db.json";
import { SET_POIS, SET_POI } from "./constants";
const initialState = {
	...db[0].pois
};

export default (state = initialState, action) => {
	switch (action.type) {
	case SET_POIS:
		return {
			...state,
			pois: action.pois
		};
	case SET_POI:
		return {
			...state,
			poi: action.poi
		};

	default:
		return state;
	}
};
