import db from "../../data/db.json";
import { SET_CANVAS } from "./constants";
import { createjs, theme } from "../../utils";

const colors = {};
const dots = [];
const floors = {};
const lci = {};

const lciCreator = (color) => {
	const lciContainer = new createjs.Container();
	const circle1Graphics = new createjs.Graphics();
	circle1Graphics.beginFill("white").drawCircle(0, 0, 36);
	const circle1Shape = new createjs.Shape(circle1Graphics);
	const circle2Graphics = new createjs.Graphics();
	circle2Graphics.beginFill("white").drawCircle(120, 0, 18);
	const circle2Shape = new createjs.Shape(circle2Graphics);
	const circle3Graphics = new createjs.Graphics();
	circle3Graphics.setStrokeStyle(14).beginStroke(color).
		drawCircle(190, 0, 70);
	const circle3Shape = new createjs.Shape(circle3Graphics);
	const lineGraphics = new createjs.Graphics();
	lineGraphics.beginFill("white").drawRect(0, -8, 120, 14);
	const lineShape = new createjs.Shape(lineGraphics);
	const lciText = new createjs.Text();
	lciText.set({
		text: "lci",
		font: "36px Questrial,Arial,sans,sans-serif",
		textAlign: "center",
		color,
		textBaseline: "middle",
		x: 0,
		y: 0
	});
	lciContainer.addChild(circle3Shape, circle1Shape, circle2Shape, lineShape, lciText);
	return lciContainer;
};


const shapes = db[0].canvas.all.pois.reduce((apartmentDotsShapes, poi, index) => {
	const [name] = Object.keys(poi);
	const { data } = poi[name];
	const color = theme.app[`backgroundColor${data.type.replace(/\D/u, "")}`];
	lci[name] = lciCreator(color);
	colors[name] = color;
	const textColor = theme.palette.primary.contrastText;
	dots[index] = new createjs.Graphics();
	dots[index].beginFill(color);
	dots[index].drawCircle(0, 0, 21);
	// dots[index].append(text);

	const tempContainer = new createjs.Container();

	const tempObj = new createjs.Shape(dots[index]);
	tempObj.x = -100;
	tempObj.y = -100;
	tempObj.title = name;

	const text = new createjs.Text();
	text.set({
		text: tempObj.title,
		font: "12px Questrial,Arial,sans,sans-serif",
		textAlign: "center",
		color: textColor,
		textBaseline: "middle",
		x: tempObj.x,
		y: tempObj.y
	});

	tempContainer.addChild(tempObj, text);

	apartmentDotsShapes[name] = tempContainer;

	return apartmentDotsShapes;
}, {});

const floorsImages = Object.keys(db[0].canvas).reduce((floorImages, floor) => {
	// floorImages[floor] = db[0].canvas[floor].images.map((src) => {
	floorImages[floor] = db[0].canvas.all.images.map((src) => {
		const image = new Image();
		image.src = `.${src}`;
		return image;
	});
	// if (floor !== "all") {
	// 	floors[floor] = db[0].canvas[floor].pois.reduce((apartaments, poi) => {
	// 		const [name] = Object.keys(poi);
	// 		const { coordinates } = poi[name];
	// 		const room = coordinates.reduce((apartmentPoints, coordinate) => {
	// 			const vertices = coordinate._vertices.split(";").map((vertice) => {
	// 				const tmp = vertice.split(",");
	// 				return [
	// 					1920 * tmp[0],
	// 					1080 * tmp[1]
	// 				];
	// 			});
	//
	// 			apartmentPoints[coordinate._column] = new createjs.Shape();
	// 			apartmentPoints[coordinate._column].graphics.beginFill(colors[name]).drawPolygon(0, 0, vertices);
	// 			apartmentPoints[coordinate._column].title = name;
	// 			apartmentPoints[coordinate._column].alpha = 0.45;
	//
	// 			return apartmentPoints;
	// 		}, []);
	// 		apartaments.push(room);
	// 		return apartaments;
	// 	}, []);
	// }
	// This is need for canvas without floor images
	floors[floor] = shapes;
	return floorImages;
}, {});

const initialState = {
	...db[0].canvas,
	floorsImages,
	shapes,
	dots,
	colors,
	floors,
	bg: new createjs.Shape(),
	bmp: new createjs.Bitmap(),
	framesCount: db[0].canvas.all.images.length,
	lci
};

export default (state = initialState, action) => {
	switch (action.type) {
	case SET_CANVAS:
		return {
			...state,
			canvas: action.canvas
		};

	default:
		return state;
	}
};
