import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { filter as styles } from "./styles";

import {
	Typography,

	withStyles
} from "@material-ui/core";

import { ReactComponent as TypeIcon } from "../../assets/images/icons/type.svg";
import { ReactComponent as EtageIcon } from "../../assets/images/icons/etage.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { filters } from "../../store";

import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Range from "./Range";

class Filter extends React.Component {

	handleActionClick = (event, value) => {
		const { item } = this.props;
		const name = item.key.toLowerCase();

		if (this.props[name]) {
			return this.props[name](value, item.type);
		}
		return false;
	}

	handleOnChange = (event) => {
		const value = event.target.getAttribute("value");
		const { item } = this.props;
		const name = item.key.toLowerCase();

		if (this.props[name]) {
			return this.props[name](value);
		}
		return false;
	}

	handleRangeChange = (values) => {
		const { item } = this.props;
		const name = item.key.toLowerCase();
		if (this.props[name]) {
			return this.props[name](values);
		}
		return false;
	}

	check = (key) => {
		const { item, filtersList } = this.props;
		const name = item.key.toLowerCase();

		if ((item.type === "radio" && (key === filtersList[name])) || (item.type === "checkbox" && (filtersList[name].indexOf(key) > -1))) {
			return true;
		}
		return false;
	}

	renderIcon () {
		const { classes, item } = this.props;
		const { isMobile } = this.context;

		if (item.icon && !isMobile) {
			if (item.icon === "type") {
				return <TypeIcon className={classNames(classes.icon)} />;
			}
			if (item.icon === "etage") {
				return <EtageIcon className={classNames(classes.icon)} />;
			}
		}

		return null;
	}

	renderTitle () {
		const { classes, item } = this.props;
		const { isMobile } = this.context;

		if ([
			"type",
			"floor"
		].indexOf(item.key) > -1 && !isMobile) {
			return (
				<Typography
					className={classNames(classes.name)}
					variant="h3"
				>
					{item.label}
				</Typography>
			);
		}

		return null;
	}

	switchTypes () {
		const { item, filtersList } = this.props;
		const { type, values } = item;

		if (type === "radio") {
			return (
				<Radio
					name={item.key}
					onChange={this.handleOnChange}
					onClick={this.handleActionClick}
					values={values}
					filters={filtersList}
				/>
			);
		}
		if (type === "checkbox") {
			return (
				<Checkbox
					colored={item.colored}
					name={item.key}
					onChange={this.handleOnChange}
					onClick={this.handleActionClick}
					values={values}
					filters={filtersList}
				/>
			);
		}
		if (type === "range") {
			return (
				<Range
					defaultValue={filtersList[item.key]}
					max={values.length > 1 ? values[1].key : 100}
					min={values.length > 1 ? values[0].key : 0}
					name={item.key}
					onChange={this.handleRangeChange}
					values={filtersList[item.key]}
					filters={filtersList}
				/>
			);
		}
		return null;
	}

	render () {
		const { classes, item, isMobile } = this.props;

		return (
			<div
				className={classNames(classes.wrapper)}
			>
				{(isMobile) ? null : this.renderIcon()}
				{(isMobile) ? null : this.renderTitle()}
				<div
					className={classNames(item.withBackground ? classes.withBackground : "", classes.actions)}
				>
					{this.switchTypes()}
				</div>

			</div>
		);
	}

}

const mapStateToProps = (state) => ({
	filtersList: {
		...state.filters
	},
	...state.helpers
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	type: filters.setType,
	floor: filters.setFloor,
	benefits: filters.setBenefit,
	price: filters.setPrice,
	surface: filters.setSurface
}, dispatch);

Filter.propTypes = {
	classes: PropTypes.object.isRequired,
	filtersList: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Filter));
