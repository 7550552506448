import db from "../../data/db.json";
import { SET_FILTER, RESET_FILTERES } from "./constants";
// This is need for checkbox floor filter
const floor = Object.keys(db[0].settings.floors);

const initialState = {
	...JSON.parse(JSON.stringify(db[0].filters)),
	floor
};

export default (state = initialState, action) => {
	let checked = 0;
	switch (action.type) {
	case RESET_FILTERES:
		return {
			...JSON.parse(JSON.stringify(db[0].filters))
		};
	case SET_FILTER:
		if (action.filterType === "checkbox") {
			if (
				action.filterName === "floor" &&
					typeof state[action.filterName] === "string"
			) {
				state[action.filterName] = [];
			}

			checked = state[action.filterName].indexOf(action.value);
			if (checked > -1) {
				state[action.filterName].splice(checked, 1);
			} else {
				state[action.filterName].push(action.value);
			}
		} else {
			state[action.filterName] = action.value;
		}
		return {
			...state
		};

	default:
		return state;
	}
};
