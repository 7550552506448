export default (theme) => ({
	drawer: {
		width: "20vw",
		flexShrink: 0,
		minWidth: 320
		// boxShadow: "none"
	},
	drawerPaper: {
		width: "20vw",
		height: "100%",
		// boxShadow: "none",
		padding: theme.spacing.unit * 1.5,
		minWidth: 320
	},
	wrapper: {
		// padding: theme.spacing.unit * 3
	},
	card: {
		marginBottom: theme.spacing.unit * 2
	},
	toolbar: theme.mixins.toolbar,
	content: {
		"&$expanded": {
			margin: 0
			// padding: 8
		}
	},
	expanded: {
		// margin: `0 0 ${theme.spacing.unit}px 0`
	},
	panelRoot: {
		marginBottom: theme.spacing.unit * 2.5,
		boxShadow: "none"
	},
	panelSummaryRoot: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		boxShadow: theme.shadows[1],
		"&:hover": {
			boxShadow: theme.shadows[3]
		},
		minHeight: "auto",
		"&$expanded": {
			padding: `0 ${theme.spacing.unit * 3}px`,
			margin: 0,
			minHeight: "auto"
		},
		"&$expanded div:first-child": {
			margin: "12px 0"
		}
	},
	panelSummaryHeading: {
		color: theme.palette.common.white,
		fontSize: "1rem",
		"&$expanded": {
			marginLeft: 20
		}
	},
	panelSummaryIcon: {
		color: theme.palette.common.white
	},
	panelDetails: {
		display: "block",
		padding: `${theme.spacing.unit}px 0 0 0`
		// maxHeight: 400,
		// overflowY: "scroll"
	},
	resultHeaderAppBar: {
		marginBottom: theme.spacing.unit * 3,
		boxShadow: "none"
	},
	resultHeaderToolbar: {
		padding: 0,
		alignItems: "center"
	},
	resultHeaderToolbarContainer: {
		justifyContent: "space-between"
	},
	resultHeaderActions: {
		width: "100%",
		textAlign: "right"
	},
	resultHeaderCount: {
		color: theme.palette.primary.main,
		fontWeight: "600",
		marginRight: theme.spacing.unit / 2,
		fontSize: "1.3rem"
	},
	resultHeaderTitle: {
		fontWeight: "600",
		marginRight: theme.spacing.unit / 2
	},
	resultHeaderButton: {
		backgroundColor: theme.palette.background.default,
		marginRight: theme.spacing.unit / 2,
		"&:last-child": {
			marginRight: 0
		},
		padding: `${theme.spacing.unit / 4}px ${(theme.spacing.unit) - 2}px`,
		fontSize: "0.75rem",
		height: "auto"
	},
	resultHeaderIcon: {
		fill: theme.palette.primary.main,
		width: 14,
		height: 14,
		marginRight: 4
	},
	[theme.breakpoints.down("md")]: {
		resultHeaderButton: {
			fontSize: "0.75rem"
		},
		resultHeaderIcon: {
			width: 12,
			height: 12
		}
	},
	[theme.breakpoints.down("sm")]: {
		drawer: {
			width: "100vw"
		},
		drawerPaper: {
			width: "100vw"
		},
		resultHeaderButton: {
			fontSize: "0.8rem"
		},
		resultHeaderIcon: {
			width: 16,
			height: 16
		},
		resultHeaderTitle: {
			marginBottom: theme.spacing.unit / 2
		}
	}
});
