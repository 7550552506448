import { SET_FILTER, RESET_FILTERES } from "./constants";
import { setFiltered } from "../filtered";

export const reset = (type) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: RESET_FILTERES
	});
	dispatch(setFiltered());
	resolve(type);
});

export const setType = (type) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_FILTER,
		value: type,
		filterName: "type",
		filterType: "checkbox"
	});
	dispatch(setFiltered());
	resolve(type);
});
export const setFloor = (floor, type) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_FILTER,
		value: floor,
		filterName: "floor",
		filterType: type
	});
	dispatch(setFiltered());
	resolve(floor);
});
export const setSurface = (surface) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_FILTER,
		value: surface,
		filterName: "surface",
		filterType: "range"
	});
	dispatch(setFiltered());
	resolve(surface);
});
export const setPrice = (price) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_FILTER,
		value: price,
		filterName: "price",
		filterType: "range"
	});
	dispatch(setFiltered());
	resolve(price);
});
export const setBenefit = (benefit) => (dispatch) => new Promise((resolve) => {
	dispatch({
		type: SET_FILTER,
		value: benefit,
		filterName: "benefits",
		filterType: "checkbox"
	});
	dispatch(setFiltered());
	resolve(benefit);
});
