import { SET_FILTERED } from "./constants";

export const getFiltered = () => (dispatch, getState) => new Promise((resolve) => {
	const { filtered } = getState();
	resolve(filtered);
});

export const setFiltered = () => (dispatch, getState) => new Promise((resolve) => {
	const { filtered, filters, pois, settings } = getState();
	dispatch({
		type: SET_FILTERED,
		filters,
		pois,
		types: settings.types
	});
	resolve(filtered);
});
