import {
	SET_WINDOW_WIDTH,
	SET_SEARCH_HIDDEN,
	SET_SIDEBAR_HIDDEN,
	SET_SEARCH_OPENED,
	SET_SIDEBAR_OPENED,
	SET_MOBILE
} from "./constants";

const isMobileInitial = window.innerWidth < 960;

const initialState = {
	window: {
		width: window.innerWidth
	},
	sidebar: {
		hidden: isMobileInitial,
		opened: !isMobileInitial
	},
	search: {
		hidden: true,
		opened: false
	},
	isMobile: isMobileInitial
};

export default (state = initialState, action) => {
	const { search, sidebar, isMobile } = state;
	const isMobileAction = action.width < 960;
	switch (action.type) {
	case SET_WINDOW_WIDTH:
		return {
			...state,
			window: {
				width: action.width
			},
			isMobileAction,
			search: {
				hidden: true,
				opened: false
			},
			sidebar: {
				hidden: isMobileAction,
				opened: !isMobileAction
			}
		};
	case SET_SEARCH_HIDDEN:
		return {
			...state,
			search: {
				hidden: action.hidden
			}
		};
	case SET_SIDEBAR_HIDDEN:
		return {
			...state,
			sidebar: {
				hidden: action.hidden
			}
		};
	case SET_SEARCH_OPENED:
		return {
			...state,
			search: {
				hidden: search.opened,
				opened: !search.opened
			},
			sidebar: {
				hidden: !isMobile && !search.opened,
				opened: !isMobile && search.opened
			}
		};
	case SET_SIDEBAR_OPENED:
		return {
			...state,
			search: {
				hidden: !isMobile && !sidebar.opened,
				opened: !isMobile && sidebar.opened
			},
			sidebar: {
				hidden: sidebar.opened,
				opened: !sidebar.opened
			}
		};
	case SET_MOBILE:
		return {
			...state,
			isMobile: action.isMobile
		};

	default:
		return state;
	}
};
