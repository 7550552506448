import db from "../../data/db.json";
import styles from "./styles";
import { withStyles, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as createjs from "@createjs/easeljs";
const fullBuildingPointsAll = db[0].canvas.all.pois;
const imagesList = db[0].canvas.all.images;
const apartmentDotsShapes = [];

class Canvas extends Component {

	state = {
		fullBuildingPoints: fullBuildingPointsAll,
		filteredData: [],
		imgList: imagesList,
		images: [],
		loaded: 0,
		currentFrame: Math.round(imagesList.length / 2),
		totalFrames: imagesList.length,
		rotate360Interval: null,
		startX: null,
		stage: null,
		bg: new createjs.Shape(),
		bmp: new createjs.Bitmap(),
		apartmentDotsGraphics: [],
		apartmentDotsShapes: [],
		canvas: null,
		floor: "all",
		floorApartments: [],
		apartmentPoints: {},
		allImagesByFloor: null,
		colors: {},
		popup: {
			x: 0,
			y: 0,
			open: false,
			poi: null,
			color: "inherit"
		}
	};

	componentDidMount () {
		const { poi, lci } = this.props;
		const lciPoint = lci[poi];

		lciPoint.x = 300;
		lciPoint.y = 400;
		lciPoint.scaleX = "0.6";
		lciPoint.scaleY = "0.6";

		apartmentDotsShapes[poi] = lciPoint;
		this.setInitialState();
	}

	handleImageLoaded = () => {
		this.setState((prevState) => ({
			...prevState,
			allImagesByFloor: Object.keys(db[0].canvas).reduce(
				(floorImages, floor) => {
					floorImages[floor] = db[0].canvas[floor].images.map((src) => {
						const image = new Image();
						image.src = `.${src}`;
						return image;
					});
					return floorImages;
				},
				{}
			),
			loaded: 0
		}));
		this.update360(this.state.currentFrame);
	};

	setInitialState = () => {
		const canvas = document.getElementById("canvas");
		this.setState(
			(prevState) => ({
				...prevState,
				canvas,
				stage: new createjs.Stage(canvas),
				allImagesByFloor: {
					all: db[0].canvas.all.images.map((src, index) => {
						const image = new Image();
						image.src = `.${src}`;
						if (prevState.totalFrames === index + 1) {
							image.onload = this.handleImageLoaded;
							image.onerror = this.handleImageErrored;
						}
						return image;
					})
				},
				loaded: 0
			}),
			() => this.init()
		);
	};

	update360 = () => {
		const { bmp, canvas, stage } = this.state;

		this.state.fullBuildingPoints.forEach((poi) => {
			const [name] = Object.keys(poi);
			if (this.props.poi === name) {
				const values = poi[name].coordinates;
				const founded = values[Math.round(values.length / 2.5)];
				bmp.image = this.state.allImagesByFloor.all[founded._column];
				const x = canvas.width * founded._x;
				const y = canvas.height * founded._y;
				apartmentDotsShapes[name].x = x - 108;
				apartmentDotsShapes[name].y = y;

				stage.addChild(apartmentDotsShapes[name]);
				stage.update();
			}
		});
	};

	handleTick = () => {
		this.state.stage.update();
	};

	init = () => {
		const { canvas, stage, bg, bmp } = this.state;

		if (!canvas || !canvas.getContext) {
			return;
		}

		stage.enableMouseOver(1);
		createjs.Touch.enable(stage);

		stage.addChild(bg);
		stage.addChild(bmp);

		// TICKER
		createjs.Ticker.addEventListener("tick", this.handleTick);
		createjs.Ticker.framerate = 60;
		createjs.Ticker.useRAF = true;
	};

	renderPopup () {
		const { popup } = this.state;
		const { open, x, y, color, poi } = popup;
		return (
			<Grid
				container
				direction="row"
				alignItems="center"
				alignContent="center"
				style={{
					display: open ? "flex" : "none",
					position: "absolute",
					top: y,
					left: x,
					width: 200,
					height: 50,
					backgroundColor: "#f4f4f4",
					zIndex: 99999
				}}
			>
				<Grid
					item
					xs={4}
					style={{
						backgroundColor: color,
						color: "white",
						height: "100%"
					}}
				>
					<Typography
						variant="h6"
						color="inherit"
						style={{
							display: "flex"
						}}
					>
						{poi ? poi.lot : null}
					</Typography>
				</Grid>
				<Grid
					item
					xs={8}
				>
					{poi ? poi.type : null}
				</Grid>
			</Grid>
		);
	}

	render () {
		const { classes } = this.props;
		return (
			<>
				<div className={classes.rootPOI}>
					<canvas
						className={classes.canvasPOI}
						id="canvas"
						width="1920"
						height="1080"
					/>
					{this.renderPopup()}
				</div>
			</>
		);
	}

}

const mapStateToProps = (state) => ({
	...state.filtered,
	pois: state.canvas.all.pois,
	lci: state.canvas.lci,
	floor: state.filters.floor
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

Canvas.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	poi: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(Canvas)));
