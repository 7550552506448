import {
	AppBar,
	// Grid,
	Toolbar,
	Typography,
	Fab,

	withStyles
} from "@material-ui/core";

import {
	Add as AddIcon,
	Search as SearchIcon,
	Menu as MenuIcon
} from "@material-ui/icons";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";
import Title from "./Title";
import Filters from "./Filters";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openSearch, openSidebar } from "../../store/helpers";

class DesktopHeader extends React.Component {

	renderMobile = () => {
		const { classes, search, sidebar, handleOpenSearch, handleOpenSidebar } = this.props;
		return (
			<div
				className={classNames(classes.advancedSearch)}
			>
				<Fab
					className={classNames(classes.btnOpenSidebar)}
					color="primary"
					onClick={handleOpenSidebar}
					style={{
						display: "inline-flex",
						width: 40,
						height: 40,
						marginRight: 20
					}}
				>
					<MenuIcon />
				</Fab>
				<Fab
					className={classNames(classes.btnOpenSidebar, search.opened && !sidebar.opened ? classes.advancedSearchOpened : "")}
					color="primary"
					onClick={handleOpenSearch}
					style={{
						display: "inline-flex",
						width: 40,
						height: 40
					}}
				>
					<SearchIcon />
				</Fab>
			</div>
		);
	}

	renderDesktop = () => {
		const { classes, handleOpenSearch, search } = this.props;
		return (
			<div
				className={classNames(classes.advancedSearch)}
			>
				<Typography
					style={{ display: "inline-flex",
						lineHeight: 2.5,
						marginRight: 20 }}
					variant="h4"
				>
					{"RECHERCHE AVANCÉE"}
				</Typography>
				<Fab
					className={classNames(classes.btnOpenSidebar, search.opened ? classes.advancedSearchOpened : "")}
					color="primary"
					onClick={handleOpenSearch}
					style={{
						display: "inline-flex",
						width: 30,
						height: 30,
						minHeight: 1
					}}
				>
					<AddIcon />
				</Fab>
			</div>

		);
	}

	render () {
		const { classes, isMobile } = this.props;

		return (
			<>
				<AppBar
					className={classNames(classes.appBar)}
					position="fixed"
				>
					<Toolbar
						className={classes.toolbar}
					>
						<Title classes={classes} />
						{(isMobile) ? null : <Filters classes={classes} />}
						{(isMobile) ? this.renderMobile() : this.renderDesktop()}
					</Toolbar>
				</AppBar>
			</>
		);
	}

}


const mapStateToProps = (state) => ({
	...state.helpers
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	handleOpenSearch: openSearch,
	handleOpenSidebar: openSidebar
}, dispatch);

DesktopHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	handleOpenSearch: PropTypes.func.isRequired,
	handleOpenSidebar: PropTypes.func.isRequired,
	isMobile: PropTypes.bool.isRequired,
	search: PropTypes.object.isRequired,
	sidebar: PropTypes.object.isRequired
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DesktopHeader));
