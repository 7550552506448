import PropTypes from "prop-types";
import React from "react";

import { ReactComponent as SurfaceIcon } from "../../assets/images/icons/surface.svg";
import { ReactComponent as SituationIcon } from "../../assets/images/icons/situation.svg";
import { ReactComponent as ExpositionIcon } from "../../assets/images/icons/exposition.svg";
import { ReactComponent as AnnexesIcon } from "../../assets/images/icons/annexes.svg";
import { ReactComponent as StatusIcon } from "../../assets/images/icons/status.svg";

import {
	Grid
} from "@material-ui/core";

import Item from "./Item";

class Content extends React.Component {

	render () {
		const { item } = this.props;
		const { loggia, balcony, terrasse, parking, surface, floor, exposition, status } = item;

		let itemFloor = (
			<span>
				<span className="superE">
					{floor}
				</span>
				{" étage"}
			</span>
		);
		if (Number(floor) === 0 || floor === "RDC") {
			itemFloor = "RDC";
		}
		if (Number(floor) === 1) {
			itemFloor = (
				<span>
					<span className="superER">
						{floor}
					</span>
					{" étage"}
				</span>
			);
		}
		return (
			<Grid
				container
			>
				<Grid
					alignContent="center"
					alignItems="center"
					container
					direction="row"
				>
					<Grid
						item
						xs={6}
					>
						<Item
							icon={SurfaceIcon}
							label={(
								<span>
									{`${surface} m²`}
								</span>
							)}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Item
							icon={AnnexesIcon}
							label={(
								<span className="benefitWrapper">
									{
										(loggia && loggia.length) ? (
											<span>
												{`Loggia: ${loggia} m²`}
											</span>
										) : null
									}
									{
										(balcony && balcony.length) ? (
											<span>
												{`Balcons: ${balcony} m²`}
											</span>
										) : null
									}
									{
										(terrasse && terrasse.length) ? (
											<span>
												{`Terrasse: ${terrasse} m²`}
											</span>
										) : null
									}
									{
										(parking && parking.length) ? (
											<span>
												{`Parking: ${parking}`}
											</span>
										) : null
									}
								</span>
							)}
						/>
					</Grid>
				</Grid>
				<Grid
					alignContent="center"
					alignItems="center"
					container
					direction="row"
				>
					<Grid
						item
						xs={6}
					>
						<Item
							icon={SituationIcon}
							label={itemFloor}
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						{exposition ? (
							<Item
								icon={ExpositionIcon}
								label={exposition}
							/>
						) : null}
						{!exposition && status ? (
							<Item
								icon={StatusIcon}
								label={status}
							/>
						) : null}

					</Grid>
				</Grid>
			</Grid>
		);
	}

}

Content.propTypes = {
	// classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired
};

export default Content;
