import PropTypes from "prop-types";
import React from "react";
import { sidebar as styles } from "./styles";
import classNames from "classnames";
import {
	Grid,
	Typography,
	Fab,

	withStyles
} from "@material-ui/core";

import { ReactComponent as SurfaceIcon } from "../../../assets/images/icons/surface.svg";
import { ReactComponent as SituationIcon } from "../../../assets/images/icons/situation.svg";
import { ReactComponent as ExpositionIcon } from "../../../assets/images/icons/exposition.svg";
import { ReactComponent as AnnexesIcon } from "../../../assets/images/icons/annexes.svg";
import { ReactComponent as StatusIcon } from "../../../assets/images/icons/status.svg";

class Sidebar extends React.Component {

	state = {
		toggleViewer: false
	}

	handleOpenViewer = () => {
		this.setState((prevState) => ({
			...prevState,
			toggleViewer: !prevState.toggleViewer
		}));
	}

	render () {
		const { classes, poi } = this.props;
		const { lot, surface, loggia, floor, exposition, parking, type, terrasse, balcony, status } = poi;
		let poiFloor = (
			<span>
				<span className="superE">
					{floor}
				</span>
				{" étage"}
			</span>
		);
		if (Number(floor) === 0 || floor === "RDC") {
			poiFloor = "RDC";
		}
		if (Number(floor) === 1) {
			poiFloor = (
				<span>
					<span className="superER">
						{floor}
					</span>
					{" étage"}
				</span>
			);
		}
		const pieces = Number(type.replace(/\D/ugmi, ""));
		return (
			<div
				className={classes.root}
			>
				<div className={classes.wrapper} >
					<Typography
						align="center"
						className={classes.title}
					>
						{`Lot N° ${lot}`}
						<span
							align="center"
							color="inherit"
							className={classes.subTitle}
						>
							{`Appartament ${pieces} ${(pieces === 1) ? "pièce" : "pièces"}`}
						</span>
					</Typography>
					<Grid
						alignItems="center"
						container
						justify="space-around"
					>
						<Grid
							className={classes.grid}
							item
							sm={6}
						>
							<SituationIcon className={classes.icon} />
							<Typography
								className={classNames(classes.subTitle, classes.grid)}
							>
								{"SITUATION"}
								<span
									color="inherit"
									className={classes.value}
								>
									{poiFloor}
								</span>
							</Typography>
						</Grid>
						<Grid
							className={classes.grid}
							item
							sm={6}
						>
							<SurfaceIcon className={classes.icon} />
							<Typography
								className={classNames(classes.subTitle, classes.grid)}
							>
								{"SURFACE"}
								<span
									color="inherit"
									className={classes.value}
								>
									{`${surface} m²`}
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid
						alignItems="center"
						container
						justify="space-around"
					>
						<Grid
							className={classes.grid}
							item
							sm={6}
						>
							{exposition ? (
								<>
									<ExpositionIcon className={classes.icon} />
									<Typography
										className={classNames(classes.subTitle, classes.grid)}
									>
										{"EXPOSITION"}
										<span
											className={classes.value}
										>
											{exposition}
										</span>
									</Typography>
								</>
							) : null}
							{!exposition && status ? (
								<>
									<StatusIcon className={classes.icon} />
									<Typography
										className={classNames(classes.subTitle, classes.grid)}
									>
										{"STATUT"}
										<span
											color="inherit"
											className={classes.value}
										>
											{status}
										</span>
									</Typography>
								</>
							) : null}

						</Grid>
						<Grid
							className={classes.grid}
							item
							sm={6}
						>
							<AnnexesIcon className={classes.icon} />
							<Typography
								className={classNames(classes.subTitle, classes.grid)}
							>
								{"ANNEXES"}
								<span
									color="inherit"
									className={classNames(classes.value, "benefitWrapper")}
								>
									{
										(loggia && loggia.length) ? (
											<span>
												{`Loggia (${loggia} m²)`}
											</span>
										) : null
									}
									{
										(balcony && balcony.length) ? (
											<span>
												{`Balcon (${balcony} m²)`}
											</span>
										) : null
									}
									{
										(terrasse && terrasse.length) ? (
											<span>
												{`Terrasse (${terrasse} m²)`}
											</span>
										) : null
									}
									{
										(parking && parking.length) ? (
											<span>
												{`Parking (${parking})`}
											</span>
										) : null
									}
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Typography
						align="center"
						className={classNames(classes.subTitle, "spacer")}
						variant="h6"
					>
						{"Plan 2D de l'appartement"}
					</Typography>
					<div className={classes.actions}>
						<Fab
							classes={{
								root: classes.btn
							}}
							href={`./data/plans/${lot}.pdf`}
							target="_blank"
							variant="extended"
						>
							{"afficher"}
						</Fab>
						<Fab
							classes={{
								root: classes.btn
							}}
							component="a"
							download={`Plan-${lot}.pdf`}
							href={`./data/plans/${lot}.pdf`}
							variant="extended"
						>
							{"TÉLÉCHARGER"}
						</Fab>
					</div>
					<div className={classes.actions}>
						<Fab
							classes={{
								root: classes.btnBig
							}}
							href="mailto:stephanie.caux@vectuel.com"
							target="_top"
							variant="extended"
						>
							{"contactez-nous"}
						</Fab>
					</div>
				</div>
			</div>
		);
	}

}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	poi: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
