import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { range as styles } from "./styles";

import { Input, InputAdornment, withStyles } from "@material-ui/core";

import { Slider } from "../index";

class Range extends Component {

	handleOnChange = (event) => {
		const { onChange } = this.props;
		let values = [];
		if (event.target) {
			const { value, name } = event.target;
			values[0] = name === "from" ? Number(value) : this.props.values[0];
			values[1] = name === "to" ? Number(value) : this.props.values[1];
		} else {
			values = event;
		}

		onChange(values);
	};

	render () {
		const { classes, min, max, defaultValue, values, name } = this.props;
		const [
			from,
			to
		] = values;

		return (
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<Input
						className={classes.textField}
						type="number"
						value={from}
						disableUnderline
						onChange={this.handleOnChange}
						inputProps={{
							min,
							max,
							// value: from,
							name: "from",
							style: {
								textAlign: "right"
							}
						}}
						variant="filled"
						startAdornment={
							<InputAdornment
								position="start"
								className={classes.adorment}
								disableTypography
							>
								{"Min"}
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment
								className={classNames(
									classes.adorment,
									`${name === "surface" ? "squared" : ""}  withMargin`
								)}
								position="end"
								disableTypography
							>
								{name === "surface" ? "m" : "€"}
							</InputAdornment>
						}
					/>
					<Input
						className={classNames(classes.textField)}
						type="number"
						value={to}
						onChange={this.handleOnChange}
						disableUnderline
						inputProps={{
							min,
							max,
							// value: to,
							name: "to",
							style: {
								textAlign: "right"
							}
						}}
						variant="filled"
						startAdornment={
							<InputAdornment
								position="start"
								className={classes.adorment}
								disableTypography
							>
								{"Max"}
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment
								className={classNames(
									classes.adorment,
									`${name === "surface" ? "squared" : ""} withMargin`
								)}
								position="end"
								disableTypography
							>
								{name === "surface" ? "m" : "€"}
							</InputAdornment>
						}
					/>
				</div>
				<Slider
					// defaultValue={defaultValue}
					onChange={this.handleOnChange}
					mode={2}
					step={1}
					domain={[
						Math.round(min),
						Math.round(max)
					]}
					reversed={false}
					onChange={this.handleOnChange}
					values={values}
				/>
			</div>
		);
	}

}

Range.propTypes = {
	classes: PropTypes.object.isRequired,
	defaultValue: PropTypes.array.isRequired,
	max: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	values: PropTypes.array.isRequired
};

export default withStyles(styles)(Range);
