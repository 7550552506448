import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { card as styles } from "./styles";
import { withRouter } from "react-router-dom";

import {
	// Typography,
	Card as MuiCard,
	CardHeader,
	Fab,
	Avatar,

	withStyles
} from "@material-ui/core";

import { ReactComponent as ShareIcon } from "../../assets/images/icons/share.svg";

import Content from "./Content";

import {
	Share
} from "../index";

class Card extends React.Component {

	constructor (props) {
		super(props);
		this.anchorEl = React.createRef();
	}

	state = {
		anchorEl: null,
		element: "button",
		open: false
	}

	setButtonRef = (element) => {
		this.anchorEl = element;
		this.setState((prevState) => ({
			...prevState,
			element
		}));
	}

	handleShareOpen = (event) => {
		this.setState({ anchorEl: event.currentTarget });
		event.cancelBubble = true;
		if (event.stopPropagation) {
			event.stopPropagation();
		}

		return false;
	}

	handleShareClose = () => {
		this.setState((prevState) => ({
			...prevState,
			anchorEl: null
		}));
	}

	goToPoi = (event) => {
		event.persist();
		const { item, history } = this.props;
		history.push(`/bien/${item.lot}`);

		event.cancelBubble = true;
		if (event.stopPropagation) {
			event.stopPropagation();
		}
		return false;
	}

	render () {
		const { classes, item } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const imgBackground = `backgroundColor${item.type.replace(/\D/ugmi, "")}`;
		const pieces = Number(item.type.replace(/\D+/ugmi, ""));

		return (
			<MuiCard
				className={classes.card}
				onClick={this.goToPoi}
			>
				<CardHeader
					action={
						<div>
							<Fab
								aria-haspopup="true"
								aria-owns={this.state.open ? "simple-popper" : null}
								buttonRef={this.setButtonRef}
								classes={{
									root: classes.cardAction
								}}
								onClick={this.handleShareOpen}
							>
								<ShareIcon className={classes.btn} />
							</Fab>
							<Share
								element={this.anchorEl}
								onClose={this.handleShareClose}
								open={open}
								url={`http://tigery.webimmo.vectuel.com/bien/${item.lot}`}
							/>
						</div>
					}
					avatar={
						<Avatar
							aria-label="Recipe"
							className={classNames(classes.avatar, imgBackground)}
						>
							{item.lot}
						</Avatar>
					}
					classes={{
						root: classes.cardHeader,
						title: classes.title
					}}
					subheader={
						<Content item={item} />
					}
					title={`Appartament ${pieces} ${(pieces === 1) ? "pièce" : "pièces"}`}
					titleTypographyProps={{
						variant: "h3"
					}}
				/>
			</MuiCard>
		);
	}

}

Card.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Card));
