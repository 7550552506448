export const header = (theme) => ({
	btnBack: {
		display: "inline-flex",
		position: "relative",
		width: 30,
		height: 30,
		minHeight: "auto",
		fontWeight: 900,
		zIndex: 1
	},
	backActions: {
		position: "absolute",
		top: 10,
		right: theme.spacing.unit * 2,
		display: "flex",
		"& p": {
			lineHeight: "2rem",
			marginRight: theme.spacing.unit * 2
		},
		fontWeight: 900,
		zIndex: 2
	},
	toolbar: theme.mixins.toolbar
});
export const main = (theme) => ({
	"@global": {
		".rc-tabs-tab": {
			height: "4rem !important",
			fontSize: "1.6rem !important",
			alignItems: "center",
			margin: "0 !important",
			float: "none !important",
			display: "block",
			width: "100%",
			textAlign: "center",
			lineHeight: "4rem",
			padding: 0,
			textTransform: "uppercase",
			fontFamily: "Questrial,Arial,sans,sans-serif"
		},
		".rc-tabs-bottom .rc-tabs-content": {
			height: "calc(100vh - 50px - 4rem)"
		},
		".rc-tabs-tab-active, .rc-tabs-tab-active:hover": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},
		".rc-tabs-tab:hover:not(.rc-tabs-tab-active)": {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText
		}
	},
	mainWrapper: {
		width: "78vw"
		// height: "100vh"
		// display: "flex",
		// justifyContent: "center"
	},
	appBar: {
		"& > div": {
			display: "flex"
		},
		// ...theme.mixins.toolbar,
		border: "0 !important"
		// top: "auto",
		// bottom: 0,
		// left: 0,
		// width: "78vw"
	},
	tabs: {
		border: 0
	},
	tab: {
		overflow: "hidden !important"
		// height: "4rem",
		// fontSize: "1.6rem"
	},
	selected: {},
	tabWrapper: {
		// width: "100%",
		// height: "100%",
		// position: "relative",
		// top: 0,
		// left: 0
	},
	iframe: {
		width: "78vw",
		height: "calc(100vh - 50px - 4rem)",
		overflow: "hidden"
	},
	canvas: {
		// height: "calc(100% - 50px)"
	},
	canvasFullHeight: {
		height: "calc(100vh - 50px)"
	},
	[theme.breakpoints.down("sm")]: {
		mainWrapper: {
			width: "100vw"
		},
		appBar: {
			width: "100vw"
		},
		iframe: {
			width: "100vw"
		}
	}
});
export const sidebar = (theme) => ({
	"@global": {
		".benefitWrapper": {
			margin: 0
		}
	},
	root: {
		width: "100%",
		height: "100vh",
		boxShadow: "none",
		padding: 0,
		color: theme.palette.common.white,
		border: 0,
		zIndex: 1,
		position: "relative"
	},
	wrapper: {
		backgroundColor: theme.palette.common.black,
		height: "100%",
		overflowY: "auto",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "4rem"
	},
	title: {
		display: "flex",
		flexDirection: "column",
		color: "#fff",
		padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 5}px`,
		fontSize: "1.5rem",
		lineHeight: "1.3rem",
		fontWeight: 600,
		flexShrink: 0,
		flexGrow: 0
	},
	subTitle: {
		fontSize: "1.1rem",
		color: "#fff",
		fontWeight: 600,
		marginTop: theme.spacing.unit / 2,
		"&.spacer": {
			marginTop: "1vh",
			flexShrink: 0,
			flexGrow: 0
		}
	},
	value: {
		fontSize: "1.1rem",
		fontWeight: 600,
		marginTop: theme.spacing.unit / 2,
		display: "block",
		textAlign: "center",
		"& > span": {
			display: "inline-flex",
			textAlign: "center"
		}
	},
	grid: {
		textAlign: "center",
		color: theme.palette.common.white,
		marginBottom: "2vh"
	},
	icon: {
		width: 35,
		height: 35,
		margin: `0 0 ${theme.spacing.unit}px 0`,
		fill: theme.palette.primary.main,
		fontWeight: 900
	},
	actions: {
		textAlign: "center",
		justifyContent: "center",
		display: "flex",
		marginTop: "2vh",
		"&:last-child": {
			marginTop: "5vh"
		}
	},
	btn: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
		fontSize: "1rem",
		marginRight: theme.spacing.unit * 2,
		"&:last-child": {
			marginRight: 0
		},
		backgroundColor: "#fff"
	},
	btnBig: {
		padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 5}px`,
		fontSize: "1.5rem",
		borderRadius: theme.spacing.unit * 8,
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main
	},
	[theme.breakpoints.down("sm")]: {
		drawerPaper: {
			width: "100vw"
		},
		drawerWrapper: {
			padding: `0 ${theme.spacing.unit * 4}px`
		},
		appBar: {
			width: "100vw"
		},
		".benefitWrapper": {
			display: "flex !important",
			flexDirection: "column",
			alignItems: "center"
		}
	},
	[theme.breakpoints.down("md")]: {
		drawerPaper: {
			width: "100vw"
		},
		drawerWrapper: {
			padding: `0 ${theme.spacing.unit * 4}px`
		},
		btn: {
			fontSize: "0.9rem"
		},
		btnBig: {
			fontSize: "1.2rem"
		}
	}
});
