import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import canvas from "./canvas";
import pois from "./pois";
import settings from "./settings";
import helpers from "./helpers";
import filters from "./filters";
import filtered from "./filtered";

export default (history) => combineReducers({
	router: connectRouter(history),
	canvas,
	pois,
	settings,
	helpers,
	filters,
	filtered
});
