import db from "../../data/db.json";
import { SET_SETTINGS } from "./constants";

const initialState = {
	...db[0].settings
};

export default (state = initialState, action) => {
	switch (action.type) {
	case SET_SETTINGS:
		return {
			...state,
			settings: action.settings
		};

	default:
		return state;
	}
};
